import HeaderComponent from "./Header/Header.component";
import SidebarComponent from "./Sidebar/Sidebar.component";
import { Outlet } from "react-router-dom";
import CenteredModal from "../Shared/modals/CenteredModal/CenteredModal";
import ImportModalContent from "../../pages/Integrations/IntegratedPlatform/ImportModalContent";
import { useIntegrationContext } from "../../context/IntegrationContext";
import {useState} from "react";
import {useIsMobile} from "../../utils/useIsMobile";
const Layout = () => {
  const { showIntegrationModal, setShowIntegrationModal } =
    useIntegrationContext();
    const isMobile = useIsMobile();
    const [isExpanded, setIsExpanded] = useState(!isMobile);
    const iconSize = isMobile ? 22 : 20;

  return (
    <>
      <HeaderComponent />
      <div className={"d-flex justify-content-center justify-content-md-between"}>
        <SidebarComponent isExpanded={isExpanded} mobileSidebar={isMobile} setIsExpanded={setIsExpanded} iconSize={iconSize} />
          <main className={"mb-5 container"} style={{ marginTop: "1rem" }}>
            <Outlet />
          </main>
      </div>
      <CenteredModal
        show={showIntegrationModal}
        children={
          <ImportModalContent
            handleClose={() => setShowIntegrationModal(false)}
          />
        }
        onHide={() => setShowIntegrationModal(false)}
      />
    </>
  );
};

export default Layout;
